
const digest = '0b78f2f4ee21f0269474b0d058e34d52df04c9f5fb8087587b61bb091df059b6';
const css = `._colorOption_1qd1b_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.5rem;
  height: 1rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  color: var(--color-white);
  font-weight: 700;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"colorOption":"_colorOption_1qd1b_1"};
export { css, digest };
  