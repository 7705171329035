
const digest = 'e3e0090948d9878b43f94b902457f5f5a0835f473f2c8c8dc6757abc34404830';
const css = `._buttonEnabled_1gkxb_1,
._buttonDisabled_1gkxb_2 {
  display: block;
  background: none;
  border: none;
  padding: 1em;
  text-align: left;
}

._buttonEnabled_1gkxb_1 {
  transition: background-color 240ms ease-in-out;
}

._buttonEnabled_1gkxb_1:hover {
  background-color: #f7f9fa;
}

._buttonDisabled_1gkxb_2 {
  color: #9ca3af;
}

._buttonContainer_1gkxb_22 {
  position: relative;
}

._title_1gkxb_26 {
  margin: 0 0 0.25rem;
  color: #011627;
  font-size: 16 / 16 * 1rem;
  font-weight: 700;
  line-height: 1;
}

._descriptionEnabled_1gkxb_34,
._descriptionDisabled_1gkxb_35 {
  margin: 0;
  font-size: 14 / 16 * 1rem;
  line-height: 1.375;
}

._descriptionDisabled_1gkxb_35 {
  color: #9ca3af;
}

._descriptionEnabled_1gkxb_34 {
  color: #606d78;
}

._hoverCard_1gkxb_49,
._hoverCardTemplate_1gkxb_50 {
  cursor: default;
  position: absolute;
  left: -215px;
  width: 200px;
  text-align: center;
  padding: 8px;
  color: #fff;
  background-color: #1f2937;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

._hoverCardTemplate_1gkxb_50 {
  top: 30px;
}

._hoverCard_1gkxb_49 {
  top: 0;
}

h3 {
  margin: 0;
}

._triangle_1gkxb_77 {
  cursor: default;
  position: absolute;
  left: -35px;
  top: 18px;
}

._triangleTemplate_1gkxb_84 {
  cursor: default;
  position: absolute;
  left: -35px;
  top: 38px;
}

._link_1gkxb_91 {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"buttonEnabled":"_buttonEnabled_1gkxb_1","buttonDisabled":"_buttonDisabled_1gkxb_2","buttonContainer":"_buttonContainer_1gkxb_22","title":"_title_1gkxb_26","descriptionEnabled":"_descriptionEnabled_1gkxb_34","descriptionDisabled":"_descriptionDisabled_1gkxb_35","hoverCard":"_hoverCard_1gkxb_49","hoverCardTemplate":"_hoverCardTemplate_1gkxb_50","triangle":"_triangle_1gkxb_77","triangleTemplate":"_triangleTemplate_1gkxb_84","link":"_link_1gkxb_91"};
export { css, digest };
  