
const digest = '8b788443c03ab4595974fc99d7c9109d26f84655644ece9c0180391dae29e396';
const css = `.schedule .body-content {
  padding: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {};
export { css, digest };
  